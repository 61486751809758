<template>
  <div class="page inPage switchPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Switch</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="基础用法" inset>
        <lee-switch v-model="kg"/>
      </lee-cell-group>
      <lee-cell-group title="禁用状态" inset>
        <lee-switch v-model="kg" disabled/>
      </lee-cell-group>
      <lee-cell-group title="自定义大小" inset>
        <lee-switch v-model="kg" :size="40"/>
      </lee-cell-group>
      <lee-cell-group title="自定义颜色" inset>
        <lee-switch v-model="kg" color="#fc0"/>
      </lee-cell-group>
      <lee-cell-group title="自定义按钮" inset>
        <lee-switch v-model="kg">
          <template #dot>
              <lee-icon :name="kg ? 'select' : 'close'"/>
          </template>
        </lee-switch>
        <lee-switch v-model="kg" color="#fc0" :size="30">
          <template #dot>
              <lee-icon :name="kg ? 'moon' : 'sun'"/>
          </template>
        </lee-switch>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {ref} from 'vue'
import {useRouter} from 'vue-router'

const router = useRouter()
const back = () => {
    router.push('/')
}
const kg = ref(false)
</script>
<style>
.switchPage .inset {
  background: var(--lee-bg-white);
  padding: 16px 0;
  display: flex;
  align-items: center;
}

.switchPage .lee-switch {
  margin-left: 16px;
}
</style>
